.query-builder {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  padding: 20px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.group-section {
  background: #f8f9fa;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  transition: box-shadow 0.3s ease;
  position: relative;
}

.filter-section {
  background: white;
  padding: 16px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  position: relative;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.filter-inputs {
  margin-top: 16px;
}

.filter-value {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}

.filter-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  position: relative;
}

.filter-type-select {
  min-width: 180px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
}

.inline-inputs {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  flex-wrap: wrap;
}

.operator-select, .category-select {
  min-width: 130px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
}

.value-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 100px;
  flex-grow: 1;
}

.value-input.date {
  min-width: 150px;
}

.date-inputs {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.value-input[type="number"] {
  text-align: right;
}

.date-range {
  display: flex;
  gap: 12px;
  align-items: center;
}

.multiselect-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  max-width: 400px;
  background: white;
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.items-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 4px;
  z-index: 10;
  display: none;
}

.items-dropdown:focus-within .items-list {
  display: block;
}

.checkbox-item {
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  align-items: center;
  cursor: pointer;
}

.checkbox-item:hover {
  background: #f5f5f5;
}

.selected-count {
  font-size: 13px;
  color: #666;
  white-space: nowrap;
}

.and-separator {
  text-align: center;
  padding: 8px 0;
  color: #666;
  font-weight: 500;
}

.or-separator-container {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.or-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  color: #666;
  font-weight: 500;
  font-size: 16px;
}

.add-button {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background: white;
  cursor: pointer;
  font-size: 14px;
  gap: 4px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
  padding: 0 8px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.status {
  color: #666;
}

.actions {
  display: flex;
  gap: 12px;
}

.cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: white;
  cursor: pointer;
}

.save-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: #333;
  color: white;
  cursor: pointer;
}

.json-formatter {
  margin-top: 24px;
  padding: 16px;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
}

.json-formatter h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 16px;
  color: #333;
}

.json-formatter pre {
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  overflow: auto;
  max-height: 400px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
}

.items-selector {
  position: static;
  flex: 1;
}

.items-dropdown {
  position: relative;
  min-width: 250px;
}

.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  border-radius: 6px 6px 0 0;
}

.group-section.collapsed .group-header {
  border-radius: 6px;
  border-bottom: none;
}

.group-header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.collapse-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  color: #666;
  transition: transform 0.2s;
}

.collapse-button:hover {
  color: #333;
}

.group-title {
  font-weight: 500;
  color: #555;
  display: flex;
  align-items: center;
  gap: 8px;
}

.group-summary {
  color: #777;
  font-size: 0.9em;
  font-weight: normal;
}

.group-content {
  padding: 16px;
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  overflow: hidden;
}

.group-section.collapsed .group-content {
  max-height: 0;
  padding: 0 16px;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}

.group-section:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.group-section.collapsed {
  background: #f8f9fa;
}

.remove-group-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #dc3545;
  border-radius: 4px;
  color: #dc3545;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.remove-group-button:hover {
  background-color: #dc3545;
  color: white;
}

.remove-group-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: #999;
  color: #999;
}

.remove-group-button:disabled:hover {
  background-color: #fff;
  color: #999;
}

/* Rimuovere stili potenzialmente conflittuali per react-select */
.items-multi-select .select__control,
.items-multi-select .select__menu,
.items-multi-select .select__option,
.items-multi-select .select__multi-value,
.items-multi-select .select__multi-value__label,
.items-multi-select .select__multi-value__remove,
.items-multi-select .select__placeholder,
.items-multi-select .select__indicator {
  /* Questi stili sono ora gestiti programmaticamente */
  background-color: initial !important;
  border-color: initial !important;
  color: initial !important;
}

/* Mantenere gli stili di base per la struttura */
.items-multi-select {
  min-width: 350px;
  flex-grow: 1;
  z-index: 5;
}

/* Stili per le opzioni con immagini - comuni in entrambe le modalità */
.item-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.item-image {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 4px;
}

/* Override di alcuni stili di react-select */
.select__control {
  border-color: #ddd !important;
  box-shadow: none !important;
  min-height: 38px !important;
}

.select__control:hover {
  border-color: #aaa !important;
}

.select__menu {
  position: absolute;
  z-index: 9999 !important;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.select__option {
  padding: 8px 12px !important;
}

.select__multi-value {
  background-color: #f0f0f0 !important;
  border-radius: 4px !important;
}

.select__multi-value__label {
  font-size: 85% !important;
  padding: 3px 6px !important;
}

.select__multi-value__remove:hover {
  background-color: #e57373 !important;
  color: white !important;
}

/* Stili aggiuntivi per i menu dropdown */
.select__menu-portal {
  z-index: 9999 !important;
}

/* Light Mode Styles */
.query-builder.light-mode {
  background-color: #f9fafb;
  color: #181C32;
}

.light-mode .group-section {
  background: #ffffff;
  border: 1px solid #E4E6EF;
  box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02);
}

.light-mode .group-header {
  background-color: #f9fafb;
  border-bottom: 1px solid #E4E6EF;
}

.light-mode .filter-section {
  background: #ffffff;
  border: 1px solid #E4E6EF;
}

.light-mode .collapse-button {
  color: #7E8299;
}

.light-mode .collapse-button:hover {
  color: #3699FF;
}

.light-mode .group-title {
  color: #181C32;
}

.light-mode .group-summary {
  color: #7E8299;
}

.light-mode .filter-type-select,
.light-mode .operator-select,
.light-mode .category-select,
.light-mode .value-input {
  background-color: #ffffff;
  color: #181C32;
  border: 1px solid #E4E6EF;
}

.light-mode .filter-type-select:focus,
.light-mode .operator-select:focus,
.light-mode .category-select:focus,
.light-mode .value-input:focus {
  border-color: #3699FF;
  box-shadow: 0 0 0 2px rgba(54, 153, 255, 0.2);
}

.light-mode .and-separator {
  color: #7E8299;
}

.light-mode .or-separator {
  background-color: #f9fafb;
  border: 1px solid #E4E6EF;
  color: #181C32;
}

.light-mode .add-button {
  background: #ffffff;
  border: 1px solid #E4E6EF;
  color: #181C32;
}

.light-mode .add-button:hover {
  background: #f9fafb;
  border-color: #3699FF;
  color: #3699FF;
}

.light-mode .close-button {
  color: #7E8299;
}

.light-mode .close-button:hover {
  color: #f64e60;
}

.light-mode .remove-group-button {
  background-color: #ffffff;
  border: 1px solid #f64e60;
  color: #f64e60;
}

.light-mode .remove-group-button:hover {
  background-color: #f64e60;
  color: #ffffff;
}

.light-mode .footer {
  background: #ffffff;
  border: 1px solid #E4E6EF;
}

.light-mode .status {
  color: #7E8299;
}

.light-mode .cancel-button {
  background: #f9fafb;
  border: 1px solid #E4E6EF;
  color: #181C32;
}

.light-mode .cancel-button:hover {
  background: #E4E6EF;
}

.light-mode .save-button {
  background: #3699FF;
  color: white;
}

.light-mode .save-button:hover {
  background: #187DE4;
}

.light-mode .json-formatter {
  background: #ffffff;
  border: 1px solid #E4E6EF;
}

.light-mode .json-formatter h3 {
  color: #181C32;
}

.light-mode .json-formatter pre {
  background: #f9fafb;
  color: #181C32;
}

/* Dark Mode Styles - triggered by .dark-skin on body */
.query-builder.dark-mode {
  background-color: #1d2330; 
  color: #ffffff;
}

.dark-mode .group-section {
  background: #12161f;
  border: 1px solid #323248;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.dark-mode .group-header {
  background-color: #12161f;
  border-bottom: 1px solid #323248;
}

.dark-mode .filter-section {
  background: #12161f;
  border: 1px solid #323248;
}

.dark-mode .collapse-button {
  color: #92929F;
}

.dark-mode .collapse-button:hover {
  color: #3699FF;
}

.dark-mode .group-title {
  color: #ffffff;
}

.dark-mode .group-summary {
  color: #92929F;
}

.dark-mode .filter-type-select,
.dark-mode .operator-select,
.dark-mode .category-select,
.dark-mode .value-input {
  background-color: #12161f;
  color: #ffffff;
  border: 1px solid #323248;
}

.dark-mode .filter-type-select:focus,
.dark-mode .operator-select:focus,
.dark-mode .category-select:focus,
.dark-mode .value-input:focus {
  border-color: #3699FF;
  box-shadow: 0 0 0 2px rgba(54, 153, 255, 0.2);
}

.dark-mode .value-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.dark-mode .and-separator {
  color: #92929F;
}

.dark-mode .or-separator {
  background-color: #12161f;
  border: 1px solid #323248;
  color: #ffffff;
}

.dark-mode .add-button {
  background: #12161f;
  border: 1px solid #323248;
  color: #ffffff;
}

.dark-mode .add-button:hover {
  background: #1a1a27;
  border-color: #3699FF;
  color: #3699FF;
}

.dark-mode .close-button {
  color: #92929F;
}

.dark-mode .close-button:hover {
  color: #f64e60;
}

.dark-mode .remove-group-button {
  background-color: #12161f;
  border: 1px solid #f64e60;
  color: #f64e60;
}

.dark-mode .remove-group-button:hover {
  background-color: #f64e60;
  color: #ffffff;
}

.dark-mode .status {
  color: #92929F;
}

.dark-mode .cancel-button {
  background: #1a1a27;
  border: 1px solid #323248;
  color: #ffffff;
}

.dark-mode .cancel-button:hover {
  background: #282840;
}

.dark-mode .save-button {
  background: #3699FF;
  color: white;
}

.dark-mode .save-button:hover {
  background: #187DE4;
}

.dark-mode .json-formatter {
  background: #12161f;
  border: 1px solid #323248;
}

.dark-mode .json-formatter h3 {
  color: #ffffff;
}

.dark-mode .json-formatter pre {
  background: #12161f;
  color: #ffffff;
}

/* Common layout styles */
.group-content {
  padding: 16px;
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  overflow: hidden;
}

.group-section.collapsed .group-content {
  max-height: 0;
  padding: 0 16px;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}

.filter-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  position: relative;
}

.inline-inputs {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  flex-wrap: wrap;
}

.items-selector {
  position: static;
  flex: 1;
}

.items-multi-select {
  min-width: 350px;
  flex-grow: 1;
  z-index: 5;
}

/* Override per assicurare la leggibilità in dark mode */
.dark-mode .select__value-container,
.dark-mode .select__single-value,
.dark-mode .select__input,
.dark-mode .select__multi-value__label {
  color: white !important;
}

.dark-mode .select__menu-list,
.dark-mode .select__option {
  color: #e0e0e0 !important;
}

.dark-mode .select__multi-value {
  background-color: #2b303b !important;
}

/* Aggiungi ulteriore contrasto per le etichette selezionate */
.dark-mode .select__multi-value__label {
  color: white !important;
  font-weight: 500;
}

/* Aumenta il contrasto per il placeholder */
.dark-mode .select__placeholder {
  color: #aaaaaa !important;
}

/* Stili per le impostazioni di paginazione */
.pagination-settings {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.light-mode .pagination-settings {
  background: #ffffff;
  border: 1px solid #E4E6EF;
}

.dark-mode .pagination-settings {
  background: #12161f;
  border: 1px solid #323248;
}

.settings-header {
  padding: 12px 16px;
}

.light-mode .settings-header {
  background-color: #f9fafb;
  border-bottom: 1px solid #E4E6EF;
}

.dark-mode .settings-header {
  background-color: #12161f;
  border-bottom: 1px solid #323248;
}

.settings-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.light-mode .settings-header h3 {
  color: #181C32;
}

.dark-mode .settings-header h3 {
  color: #ffffff;
}

.settings-content {
  padding: 16px;
}

.settings-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.settings-field {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
}

.settings-field label {
  margin-bottom: 6px;
  font-weight: 500;
}

.light-mode .settings-field label {
  color: #181C32;
}

.dark-mode .settings-field label {
  color: #e0e0e0;
}

.field-description {
  margin-top: 6px;
  font-size: 12px;
}

.light-mode .field-description {
  color: #7E8299;
}

.dark-mode .field-description {
  color: #92929F;
}

/* Stili per gli input */
.settings-field .value-input {
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
}

.light-mode .settings-field .value-input {
  background-color: #ffffff;
  color: #181C32;
  border: 1px solid #E4E6EF;
}

.light-mode .settings-field .value-input:focus {
  border-color: #3699FF;
  box-shadow: 0 0 0 2px rgba(54, 153, 255, 0.2);
}

.dark-mode .settings-field .value-input {
  background-color: #12161f;
  color: #ffffff;
  border: 1px solid #323248;
}

.dark-mode .settings-field .value-input:focus {
  border-color: #3699FF;
  box-shadow: 0 0 0 2px rgba(54, 153, 255, 0.2);
}

/* Divisore per separare le impostazioni di paginazione dall'ordinamento */
.settings-divider {
  height: 1px;
  margin: 16px 0;
  width: 100%;
}

.light-mode .settings-divider {
  background-color: #E4E6EF;
}

.dark-mode .settings-divider {
  background-color: #323248;
}

/* Margine superiore aggiuntivo per la riga delle categorie */
.mt-2 {
  margin-top: 12px;
}

/* Disabilita lo stile sugli elementi disabilitati */
.light-mode select:disabled, 
.light-mode input:disabled {
  background-color: #f5f5f5;
  color: #999;
  cursor: not-available;
}

.dark-mode select:disabled, 
.dark-mode input:disabled {
  background-color: #1a1a27;
  color: #666;
  cursor: not-available;
}

/* Stili per il pulsante di ricerca */
.search-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: #3699FF;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-button:hover {
  background: #187DE4;
}

.search-button:disabled {
  background: #92929F;
  cursor: not-allowed;
}

/* Stili per i risultati della ricerca */
.query-builder .search-results {
  margin-top: 24px;
  padding: 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.light-mode .search-results {
  background: #ffffff;
  border: 1px solid #E4E6EF;
}

.dark-mode .search-results {
  background: #12161f;
  border: 1px solid #323248;
}

.search-results h3 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
}

.light-mode .search-results h3 {
  color: #181C32;
}

.dark-mode .search-results h3 {
  color: #ffffff;
}

.results-table-container {
  overflow-x: auto;
  max-height: 500px;
  overflow-y: auto;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.results-table th, .results-table td {
  padding: 12px;
  text-align: left;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.light-mode .results-table th, .light-mode .results-table td {
  border-bottom-color: #E4E6EF;
}

.dark-mode .results-table th, .dark-mode .results-table td {
  border-bottom-color: #323248;
}

.light-mode .results-table th {
  background-color: #f9fafb;
  color: #5E6278;
}

.dark-mode .results-table th {
  background-color: #12161f;
  color: #92929F;
}

.results-table tr:hover td {
  transition: background-color 0.2s;
}

.light-mode .results-table tr:hover td {
  background-color: #f9fafb;
}

.dark-mode .results-table tr:hover td {
  background-color: #12161f;
}

/* Stili per il loader */
.search-results-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin-top: 24px;
  border-radius: 8px;
}

.light-mode .search-results-loading {
  background: #ffffff;
  border: 1px solid #E4E6EF;
}

.dark-mode .search-results-loading {
  background: #12161f;
  border: 1px solid #323248;
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3699FF;
  animation: spin 1s linear infinite;
}

.dark-mode .spinner {
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-top-color: #3699FF;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.search-results-loading p {
  margin-top: 16px;
  font-size: 14px;
}

.light-mode .search-results-loading p {
  color: #5E6278;
}

.dark-mode .search-results-loading p {
  color: #92929F;
}

/* Stili per gli errori */
.search-results-error {
  padding: 16px;
  margin-top: 24px;
  border-radius: 8px;
  background-color: #fff5f8;
  border: 1px solid #ffe2eb;
  color: #f64e60;
}

.dark-mode .search-results-error {
  background-color: #12161f;
  border: 1px solid #323248;
  color: #f64e60;
}

/* Stili per box come in usersV2.js */
.box {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  background-color: #131720;
  border-radius: 10px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  transition: all 0.3s ease;
}

.box .box-controls {
  margin-left: 20px;
}


.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.25rem;
  border-bottom: 1px solid #ffffff1f;
}

.box-header.with-border {
  border-bottom: 1px solid #ffffff1f;
}

.box-title {
  font-size: 18px;
  margin: 0;
  color: #ffffff;
  font-weight: 500;
}

.box-body {
  padding: 1.25rem;
  border-radius: 0 0 10px 10px;
}

/* Stili per i bottoni come in usersV2.js */
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-primary {
  color: #fff;
  background-color: #3699FF;
  border-color: #3699FF;
}

.btn-primary:hover {
  color: #fff;
  background-color: #187DE4;
  border-color: #187DE4;
}

/* Stili per lo spinner */
.spinner-border {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: text-bottom;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

/* Fix per MaterialReactTable */
.MuiTableContainer-root {
  overflow-x: auto !important;
}

.MuiTableContainer-root::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #323248;
  border-radius: 4px;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  background-color: #12161f;
}

/* Stili specifici per container dei risultati */
.search-results-container {
  margin-top: 24px;
}

/* Stili per CSVLink */
a.btn-primary, a.btn-primary:hover {
  text-decoration: none;
  color: white;
} 