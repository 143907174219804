.global-search-container {
  position: relative;
  min-width: 300px;
}

@media (max-width: 991px) {
  .global-search-container {
    display: none;
  }
}

.global-search-input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: #fff;
  padding: 8px 12px;
  width: 100%;
}

.global-search-input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.3);
}

.global-search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #1d2330;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  margin-top: 4px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
}

.search-loading {
  padding: 20px;
  text-align: center;
}

.search-result-item {
  display: flex;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.result-image {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.result-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.result-info {
  flex: 1;
}

.result-title {
  color: #fff;
  font-size: 14px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-type {
  font-size: 12px;
  color: #6c757d;
  text-transform: uppercase;
}

.result-subtitle {
  color: #6c757d;
  font-size: 12px;
} 