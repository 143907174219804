.dark-logo img {
  width: 155px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: #fff;
}


/* .queryBuilder > .ruleGroup > .ruleGroup-header > .ruleGroup-remove {
  display: none;
}

.queryBuilder > .ruleGroup > .ruleGroup-body .ruleGroup-addGroup {
  display: none;
} */
.svg-font-color svg > path {
  fill: var(--ifm-font-color-base);
}

.donut-hole {
  overflow-y: unset !important;
}

.queryBuilder {
  min-width: 420px;
}

/* Styles for when "Use validation" option is selected */
.validateQuery .queryBuilder {
  & .ruleGroup.queryBuilder-invalid {
    /* Color invalid groups */
    background-color: color-mix(in srgb, rebeccapurple, transparent 60%);

    & .ruleGroup-addRule {
      /* Bold the "+ Rule" button if the group has no child rules or groups */
      font-weight: bold !important;
    }

    & > .ruleGroup-header::after {
      /* Message to user about empty groups */
      content: "Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.";
      color: white;
    }
  }

  & .rule.queryBuilder-invalid .rule-value {
    /* Purple background for empty text fields */
    background-color: color-mix(in srgb, rebeccapurple, transparent 60%);

    &::placeholder {
      color: color-mix(in srgb, rebeccapurple, black 30%);
    }
  }
}

/* Styles for when "Use validation" option is selected (dark theme variations) */
html[data-theme="dark"]
  .validateQuery
  .queryBuilder
  .rule.queryBuilder-invalid
  .rule-value::placeholder {
  color: color-mix(in srgb, rebeccapurple, white 30%);
}

/* Styles for when "Justified layout" option is selected */
.justifiedLayout {
  & .queryBuilder {
    /* Push the clone, lock, and remove buttons to the right edge */
    & .ruleGroup-addGroup {
      & + button.ruleGroup-cloneGroup,
      & + button.ruleGroup-lock,
      & + button.ruleGroup-remove {
        margin-left: auto !important;
      }
    }
    & .rule-operators,
    & .rule-value,
    & .control,
    & .chakra-select__wrapper,
    & .mantine-InputWrapper-root + input[type="hidden"] {
      & + button.rule-cloneRule,
      & + button.rule-lock,
      & + button.rule-remove {
        margin-left: auto !important;
      }
    }
  }
}

/* 
.queryBuilder select, .queryBuilder input {
  width: 300px;
} */